import { Params } from '@angular/router';
import moment from 'moment';
import {
  BookingAggregate,
  BookingPaymentMethodType,
  BookingPaymentMethodVariant,
  CancellationFeeReason,
  CancellationOriginType,
  CancellationReason,
  OperationType,
  PriceType,
} from 'src/app/shared/models/booking.model';
import { BookingSearchForm, Page } from 'src/app/shared/models/elastic.vm.model';
import { OriginType, PaymentOperationSource } from 'src/app/shared/models/enums.model';
import Helpers from 'src/app/shared/utils/helpers';
import { Location } from '../../../../../../src/app/shared/models/location.model';
import { DriverAggregate, DriverStateJob, DriverStateVM } from '../models/driver.model';
import { OriginDriverStateStatusType, PosTerminalType, StopType } from '../models/enums';
import { ProductFeature } from '../models/product-feature.model';
import { ProductFeatureVM } from '../models/product-feature.vm.module';

export function getOriginTypeName(type: OriginType) {
  const originType = OriginType[type];
  return originType
    ? Helpers.capitalize(originType.replace('ORIGIN_TYPE_', '').replace('_', ' ').toLocaleLowerCase())
    : 'Undefined';
}

export function getCancellationOriginTypeName(type: CancellationOriginType) {
  switch (type) {
    case CancellationOriginType.RIDER_APP:
      return 'Rider app';
    case CancellationOriginType.DRIVER_APP:
      return 'Driver app';
    case CancellationOriginType.FLEET_COMMANDER:
      return 'Fleet commander';
    case CancellationOriginType.ORIGIN_TYPE_CPH_AIRPORT_TMS:
      return 'CPH airport TMS';
    case CancellationOriginType.ORIGIN_TYPE_DEVELOPER_APP:
      return 'Developer app';
    case CancellationOriginType.ORIGIN_TYPE_TRACKING_WEB:
      return 'Tracking web';
    case CancellationOriginType.ORIGIN_TYPE_B2B_WEB:
      return 'B2b web';
    default:
      return 'Undefined';
  }
}

export function getCancellationReason(reason: CancellationReason) {
  switch (reason) {
    case CancellationReason.NO_SHOW:
      return 'No show';
    case CancellationReason.MISSING_EQUIPMENT:
      return 'Missing equipment';
    case CancellationReason.CANNOT_FIT_LUGGAGE:
      return 'Cannot fit luggage';
    case CancellationReason.PICKUP_TOO_FAR:
      return 'Pickup too far';
    case CancellationReason.RIDER_BEHAVIOR:
      return 'Rider behavior';
    case CancellationReason.ROAD_CONDITIONS:
      return 'Road/traffic conditions';
    case CancellationReason.CAR_ISSUES:
      return 'Car issues';
    case CancellationReason.STREET_TRIP:
      return 'Street trip';
    case CancellationReason.RIDER_ADJUSTING_TRIP:
      return 'Address change';
    case CancellationReason.OTHER:
      return 'Other';
    default:
      return 'Undefined';
  }
}

export function getCancellationFeeReason(reason: CancellationFeeReason) {
  switch (reason) {
    case CancellationFeeReason.DRIVER_NOT_PAIRED:
      return 'Driver not paired';
    case CancellationFeeReason.DRIVER_DID_NOT_MOVE_TOWARDS_PICKUP:
      return 'Driver did not move towards pickup';
    case CancellationFeeReason.DRIVER_IS_LATE_FOR_PICKUP:
      return 'Driver is late for pickup';
    case CancellationFeeReason.CANCELLING_TOO_LATE:
      return 'Cancelling too late';
    case CancellationFeeReason.CANCELLING_TOO_OFTEN:
      return 'Cancelling too often';
    case CancellationFeeReason.CANCELLING_IN_TIME:
      return 'Cancelling in time';
    case CancellationFeeReason.IN_VEHICLE_PAYMENT:
      return 'In vehicle payment';
    default:
      return 'Undefined';
  }
}

export function getNetAmountLabelText(priceType?: PriceType) {
  switch (priceType) {
    case PriceType.NOSHOW:
      return 'No show fee';
    case PriceType.CANCELLATION:
      return 'Cancellation fee';
    default:
      return null;
  }
}

export function getDriverTitle(aggregate?: DriverAggregate): string {
  return aggregate ? [aggregate.driver.first_name, aggregate.driver.last_name].filter(Boolean).join(' ') : null;
}

export function getLocationAddress(location?: Location) {
  return location
    ? location.name
      ? location.name.concat(', ', location.formatted_address)
      : location.formatted_address
    : null;
}

export function getPriceType(type: PriceType) {
  switch (type) {
    case PriceType.UNDEFINED:
      return 'Undefined';
    case PriceType.QUOTE:
      return 'Quote';
    case PriceType.MANUAL:
      return 'Manual';
    case PriceType.CALCULATED:
      return 'Calculated';
    case PriceType.METERED:
      return 'Metered';
    case PriceType.CANCELLATION:
      return 'Cancellation';
    case PriceType.NOSHOW:
      return 'No show';
    default:
      return 'Unknown';
  }
}

export function showBookingDriverTelemetry(aggregate: BookingAggregate): boolean {
  if (aggregate.trip?.ended_at) {
    return false;
  }

  if (aggregate.booking?.cancelled_at) {
    return false;
  }

  if (aggregate.job?.completed_at) {
    return false;
  }

  if (aggregate.job?.ended_at) {
    return false;
  }

  if (aggregate.job?.in_progress_at) {
    return true;
  }

  return !!aggregate.job?.accepted_at;
}

export function mapQueryParamsToSearchForm(params: Params, areaIds?: string[]): BookingSearchForm {
  let searchFrom = {
    advanced: false,
  } as BookingSearchForm;

  if (params?.query) {
    searchFrom = Helpers.decode<BookingSearchForm>(params.query);
  }

  if (areaIds?.length > 0) {
    searchFrom.areas = areaIds;
  }

  return searchFrom;
}

export function mapQueryParamsToPage(params: Params, userPageSize?: number): Page {
  let page = {
    previousPageIndex: 0,
    pageIndex: 0,
    pageSize: 10,
  } as Page;

  if (userPageSize) {
    page.pageSize = userPageSize;
  }

  if (params?.page) {
    page = Helpers.decode<Page>(params.page);
  }

  return page;
}

export function getDriverStatusType(type: OriginDriverStateStatusType) {
  switch (type) {
    case OriginDriverStateStatusType.BUSY:
      return 'Busy';
    case OriginDriverStateStatusType.ONLINE:
      return 'Online';
    case OriginDriverStateStatusType.OFFLINE:
      return 'Offline';
    default:
      return 'Undefined';
  }
}

export function getStopType(type: StopType) {
  switch (type) {
    case StopType.PICKUP:
      return 'Pickup';
    case StopType.DROPOFF:
      return 'Dropoff';
    case StopType.WAYPOINT:
      return 'Waypoint';
    default:
      return 'Undefined';
  }
}

export function getOperationType(type: OperationType) {
  switch (type) {
    case OperationType.CHARGED:
      return 'charged';
    case OperationType.CHARGED_BACK:
      return 'charged back';
    case OperationType.REFUNDED:
      return 'refunded';
    case OperationType.UNDEFINED:
      return 'undefined';
    default:
      return 'unknown';
  }
}

export function getBookingPaymentMethodType(type: BookingPaymentMethodType) {
  switch (type) {
    case BookingPaymentMethodType.PAYMENT_METHOD_TYPE_UNDEFINED:
      return 'Undefined';
    case BookingPaymentMethodType.PAYMENT_METHOD_TYPE_ONLINE:
      return 'Card';
    case BookingPaymentMethodType.PAYMENT_METHOD_TYPE_INVOICE:
      return 'Invoice';
    case BookingPaymentMethodType.PAYMENT_METHOD_TYPE_IN_PERSON:
      return 'In person';
    default:
      return null;
  }
}

export function getBookingPaymentMethodVariant(variant: BookingPaymentMethodVariant) {
  switch (variant) {
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_UNDEFINED:
      return 'Undefined';
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_CARD:
      return 'Card';
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_APPLE_PAY:
      return 'Apple pay';
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_GOOGLE_PAY:
      return 'Google pay';
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_CASH:
      return 'Cash';
    case BookingPaymentMethodVariant.PAYMENT_METHOD_VARIANT_POS:
      return 'POS';
    default:
      return null;
  }
}

// TODO move to utils
export function formatDriverEta(eta: number): string {
  return 'ETA: ' + moment.duration(eta, 's').minutes().toString() + ' min';
}

export function mapProductFeatures(featureIds: string[], features: ProductFeature[]): ProductFeatureVM[] {
  return featureIds
    ?.map(id =>
      Object.assign(
        {},
        id,
        features.find(f => f.id === id),
      ),
    )
    .map(feature => ({
      id: feature.id,
      name: feature.name,
      icon: feature.icon,
    }));
}

export function getDriverStateVM(
  id: string,
  type: OriginDriverStateStatusType,
  job: DriverStateJob,
  vehicleId: string,
): DriverStateVM {
  if (job != null && job.accepted_at != null && job.in_progress_at == null) {
    return {
      id,
      sort: 2,
      status: 'Accepted',
      displayInMap: true,
      vehicleId,
    };
  }
  switch (type) {
    case OriginDriverStateStatusType.BUSY:
      return {
        id,
        sort: 1,
        status: 'Busy',
        displayInMap: true,
        vehicleId,
      };
    case OriginDriverStateStatusType.ONLINE:
      return {
        id,
        sort: 3,
        status: 'Online',
        displayInMap: true,
        vehicleId,
      };
    case OriginDriverStateStatusType.OFFLINE:
      return {
        id,
        sort: 4,
        status: 'Offline',
        displayInMap: false,
        vehicleId,
      };
    default:
      return {
        id,
        sort: 5,
        status: 'Undefined',
        displayInMap: false,
        vehicleId,
      };
  }
}

export function getPosTerminalType(type: PosTerminalType) {
  switch (type) {
    case PosTerminalType.POS_TERMINAL_TYPE_ADYEN:
      return 'Adyen';
    case PosTerminalType.POS_TERMINAL_TYPE_SUM_UP_AIR:
      return 'SumUp Air';
    case PosTerminalType.POS_TERMINAL_TYPE_SUM_UP_SOLO:
      return 'SumUp Solo';
    case PosTerminalType.POS_TERMINAL_TYPE_NONE:
      return 'None';
    default:
      return 'Undefined';
  }
}

export function getPaymentOperationSource(source: PaymentOperationSource) {
  switch (source) {
    case PaymentOperationSource.SOURCE_ADYEN:
      return 'Adyen';
    case PaymentOperationSource.SOURCE_SUM_UP:
      return 'SumUp';
    default:
      return 'Undefined';
  }
}
