export enum DayOfWeek {
  DAY_OF_WEEK_UNDEFINED = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum AreaPolygonType {
  FLATRATE = 1,
  DELIVERY = 2,
}

export enum OriginDriverStateStatusType {
  UNDEFINED = 0,
  OFFLINE = 1,
  ONLINE = 2,
  BUSY = 3,
}

export enum BusyReason {
  UNDEFINED = 0,
  STREET_TRIP_FLOW_STARTED = 1,
}

export enum StopType {
  UNDEFINED = 0,
  PICKUP = 1,
  WAYPOINT = 2,
  DROPOFF = 3,
}

export enum OfferStatusType {
  UNDEFINED = 0,
  ASSIGNED = 1,
  REJECTED = 2,
  ACCEPTED = 4,
  EXPIRED = 9,
  CANCELLED = 12,
}

export enum OfferStopType {
  UNDEFINED = 0,
  PICKUP = 1,
  WAYPOINT = 2,
  DROPOFF = 3,
}

export enum PlatformSettingType {
  SETTING_TYPE_UNDEFINED = 0,
  SETTING_TYPE_BOOLEAN = 1,
  SETTING_TYPE_INTEGER = 2,
  SETTING_TYPE_DOUBLE = 3,
  SETTING_TYPE_STRING = 4,
  SETTING_TYPE_DURATION = 5,
}

export enum PosTerminalType {
  POS_TERMINAL_TYPE_UNDEFINED = 0,
  POS_TERMINAL_TYPE_NONE = 1,
  POS_TERMINAL_TYPE_ADYEN = 2,
  POS_TERMINAL_TYPE_SUM_UP_AIR = 3,
  POS_TERMINAL_TYPE_SUM_UP_SOLO = 4,
}
